import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { AuthActions, AuthSelectors } from '..';
import { AreaSelectVM } from '../../shared/models/area-select.vm.model';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-area-select',
  templateUrl: './user-area-select.component.html',
  styleUrls: ['./user-area-select.component.scss'],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, MatCheckboxModule, FormsModule],
})
export class UserAreaSelectComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  areas$: Observable<AreaSelectVM[]>;
  isVisible$: Observable<boolean>;
  selected$: Observable<string[]>;
  areaNames$: Observable<string>;

  checkedStatus: { [id: string]: boolean } = {};

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.areas$ = this.store.select(AuthSelectors.areaVMListSelector);
    this.selected$ = this.store.select(AuthSelectors.userAreaIdsSelector);

    this.isVisible$ = this.store.select(AuthSelectors.isAreaSelectorVisibleSelector);
    this.areaNames$ = this.store.select(AuthSelectors.areaNamesSelector);

    combineLatest([this.selected$, this.areas$])
      .pipe(
        takeUntil(this.destroy$),
        map(([selectedAreaIds, areas]) => {
          const checkedStatus = {};
          areas.forEach(area => {
            checkedStatus[area.id] = selectedAreaIds.includes(area.id);
          });
          return checkedStatus;
        }),
      )
      .subscribe(checkedStatus => {
        this.checkedStatus = checkedStatus;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickApply() {
    this.store.dispatch(
      AuthActions.userAreasChangeApplyClicked({
        selectedAreaIds: Object.keys(this.checkedStatus).filter(id => this.checkedStatus[id]),
      }),
    );
  }
}
