import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, take } from 'rxjs';
import { mapEntityDeltaChangeToEntityToHtml } from 'src/app/shared/mappings/entity-deltas.mapping';
import { EntityDeltaVM } from 'src/app/shared/models/entity-delta.vm.model';
import { EntityChangesSelector } from '../store';
import { EntityMapping } from '../store/entity-logs.selectors';

@Component({
  selector: 'app-driver-change',
  imports: [],
  templateUrl: './driver-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverChangeComponent implements OnInit {
  @Input() change: EntityDeltaVM;
  mappedEntityDeltasHtml: string;
  constructor(private store: Store) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(EntityChangesSelector.dispatchTagsMappingSelector),
      this.store.select(EntityChangesSelector.areasMappingSelector),
    ])
      .pipe(
        filter(([...mappings]) => mappings.every(mapping => !!mapping)),
        take(1),
      )
      .subscribe(([...mappings]) => {
        const changesWithMappings = this.appendMappingDataToChanges(...mappings);

        this.change = {
          ...this.change,
          changes: changesWithMappings,
        };

        this.mappedEntityDeltasHtml = mapEntityDeltaChangeToEntityToHtml(this.change);
      });
  }

  private appendMappingDataToChanges(dispatchTagsMappings: EntityMapping[], areasMappings: EntityMapping[]) {
    return this.change.changes.map(change => {
      switch (change.name) {
        case 'DispatchTagIds':
          return {
            ...change,
            mappingData: dispatchTagsMappings,
          };
        case 'AreaId':
          return {
            ...change,
            mappingData: areasMappings,
          };
        default:
          return change;
      }
    });
  }
}
