import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getPosTerminalType } from 'projects/operator/src/app/shared/mappings/mappings';
import { PosTerminalType } from 'projects/operator/src/app/shared/models/enums';
import { combineLatest, filter, take } from 'rxjs';
import { mapEntityDeltaChangeToEntityToHtml } from 'src/app/shared/mappings/entity-deltas.mapping';
import { EntityDeltaVM } from 'src/app/shared/models/entity-delta.vm.model';
import { EntityChangesSelector } from '../store';
import { EntityMapping } from '../store/entity-logs.selectors';

@Component({
  selector: 'app-vehicle-change',
  imports: [],
  templateUrl: './vehicle-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleChangeComponent implements OnInit {
  @Input() change: EntityDeltaVM;
  mappedEntityDeltasHtml: string;
  posTerminalTypeMappings: EntityMapping[] = [];

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.mapPosTerminalTypes();
  }

  private mapPosTerminalTypes() {
    for (let item in PosTerminalType) {
      if (isNaN(Number(item))) {
        this.posTerminalTypeMappings.push({
          id: PosTerminalType[item],
          name: getPosTerminalType(+PosTerminalType[item]),
        });
      }
    }

    this.mapChangesToHtml();
  }

  private mapChangesToHtml() {
    combineLatest([
      this.store.select(EntityChangesSelector.vehicleTypesMappingSelector),
      this.store.select(EntityChangesSelector.productFeaturesMappingSelector),
      this.store.select(EntityChangesSelector.dispatchTagsMappingSelector),
      this.store.select(EntityChangesSelector.partnersMappingSelector),
      this.store.select(EntityChangesSelector.partnerTransportLicensesMappingSelector),
    ])
      .pipe(
        filter(([...mappings]) => mappings.every(mapping => !!mapping)),
        take(1),
      )
      .subscribe(([...mappings]) => {
        const changesWithMappings = this.appendMappingDataToChanges(...mappings);

        this.change = {
          ...this.change,
          changes: changesWithMappings,
        };

        this.mappedEntityDeltasHtml = mapEntityDeltaChangeToEntityToHtml(this.change);
        this.mappedEntityDeltasHtml && this.cdRef.markForCheck();
      });
  }

  private appendMappingDataToChanges(
    vehicleTypesMappings: EntityMapping[],
    productFeaturesMappings: EntityMapping[],
    dispatchTagsMappings: EntityMapping[],
    partnersMappings: EntityMapping[],
    partnerTransportLicensesMappings: EntityMapping[],
  ) {
    return this.change.changes.map(change => {
      switch (change.name) {
        case 'VehicleTypeId':
          return {
            ...change,
            mappingData: vehicleTypesMappings,
          };
        case 'FeatureIds':
          return {
            ...change,
            mappingData: productFeaturesMappings,
          };
        case 'DispatchTagIds':
          return {
            ...change,
            mappingData: dispatchTagsMappings,
          };
        case 'PartnerCompanyId':
          return {
            ...change,
            mappingData: partnersMappings,
          };
        case 'PosTerminalType':
          return {
            ...change,
            mappingData: this.posTerminalTypeMappings,
          };
        case 'TransportLicenseId':
          return {
            ...change,
            mappingData: partnerTransportLicensesMappings,
          };
        default:
          return change;
      }
    });
  }
}
