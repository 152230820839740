import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { AuthActions } from '..';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoginComponent {
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private store: Store<AppState>) {}

  hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  onResetPasswordClick() {
    this.store.dispatch(AuthActions.resetPasswordButtonClicked({ email: this.loginForm.controls.email.value }));
  }

  onLoginClick() {
    this.store.dispatch(
      AuthActions.loginButtonClicked({
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value,
      }),
    );
  }

  onGoogleSigninClick() {
    this.store.dispatch(AuthActions.googleSignInClicked());
  }
}
