import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from './app.state';
import { AuthSelectors } from './auth';
import { MaterialIconService } from './shared/services/material-icon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'viggo-console';

  isInitialized$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private materialIconService: MaterialIconService,
    private swUpdate: SwUpdate,
  ) {}

  ngOnInit(): void {
    this.isInitialized$ = this.store.select(AuthSelectors.isInitializedSelector);
    this.isLoggedIn$ = this.store.select(AuthSelectors.isLoggedInSelector);
    this.materialIconService.registerIcons();
  }
}
