import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, Signal, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { AppRoutingModule } from '../app-routing.module';
import { AppState } from '../app.state';
import { AuthActions, AuthSelectors } from '../auth';
import { whenPageVisible } from '../shared/utils/when-page-visible';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    AppRoutingModule,
    MatExpansionModule,
    MatDividerModule,
    NgOptimizedImage,
  ],
})
export class NavComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  isOperatorVisible: Signal<boolean>;
  isSupportAgentVisible: Signal<boolean>;
  isB2bAccountManagerVisible: Signal<boolean>;
  isFinanceManagerVisible: Signal<boolean>;

  isOperatorActive: Signal<boolean>;
  isSupportAgentActive: Signal<boolean>;
  isB2bAccountManagerActive: Signal<boolean>;
  isFinanceManagerActive: Signal<boolean>;
  isLevel1: Signal<boolean>;
  isLevel2: Signal<boolean>;

  operatorMenu = signal([
    // { name: 'Live map', route: '/operator/live-map', icon: 'map' }, // TODO fix live map performance
    { name: 'Trips', route: '/operator/trips', icon: 'list' },
    { name: 'Drivers', route: '/operator/drivers', icon: 'people' },
    { name: 'Partner companies', route: '/operator/partners', icon: 'assignment_ind' },
    { name: 'Vehicles', route: '/operator/vehicles', icon: 'local_taxi' },
  ]);
  operatorLevel1Menu = signal([
    { name: 'Map', route: '/operator/map', icon: 'map' },
    { name: 'Areas', route: '/operator/areas', icon: 'format_shapes' },
    { name: 'Polygons', route: '/operator/polygons', icon: 'layers' },
    { name: 'Dispatch queue zones', route: '/operator/dispatch-queue-zones', icon: 'layers' },
    { name: 'Flat rates', route: '/operator/flatrates', icon: 'multiple_stop' },
    { name: 'Tariffs', route: '/operator/tariffs', icon: 'money' },
    { name: 'Discounts', route: '/operator/discounts', icon: 'sell' },
    { name: 'External fees', route: '/operator/external-fees', icon: 'monetization_on' },
    { name: 'Product features', route: '/operator/product-features', icon: 'list_alt' },
    { name: 'Product packages', route: '/operator/product-packages', icon: 'widgets' },
  ]);
  operatorLevel2Menu = signal([
    { name: 'Vehicle types', route: '/operator/product-vehicle-types', icon: 'settings' },
    { name: 'VAT rates', route: '/operator/vat-rates', icon: 'receipt' },
    { name: 'Dispatching schedules', route: '/operator/max-eta-schedules', icon: 'access_time' },
    { name: 'Partner adjustments', route: '/operator/partner-adjustments', icon: 'account_balance_wallet' },
    { name: 'Platform settings', route: '/operator/platform-settings', icon: 'settings' },
  ]);
  supportAgentMenu = signal([
    { name: 'Riders', route: '/support-agent/riders', icon: 'directions_run' },
    { name: 'Drivers', route: '/support-agent/drivers', icon: 'people' },
    { name: 'Businesses', route: '/support-agent/businesses', icon: 'business_center' },
    { name: 'Trips', route: '/support-agent/trips', icon: 'list' },
  ]);
  b2bAccountManagerMenu = signal([
    { name: 'Businesses', route: '/b2baccountmanager/businesses', icon: 'business_center' },
  ]);
  financeManagerMenu = signal([
    { name: 'Bank files', route: '/finance-manager/bank-files', icon: 'file_present' },
    { name: 'Partner companies', route: '/finance-manager/partner-companies', icon: 'business_center' },
  ]);

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isOperatorVisible = this.store.selectSignal(AuthSelectors.hasOperatorRoleSelector);
    this.isSupportAgentVisible = this.store.selectSignal(AuthSelectors.hasSupportAgentRoleSelector);
    this.isB2bAccountManagerVisible = this.store.selectSignal(AuthSelectors.hasB2BAccountManagerRoleSelector);
    this.isFinanceManagerVisible = this.store.selectSignal(AuthSelectors.hasFinanceManagerRoleSelector);

    this.isOperatorActive = this.store.selectSignal(AuthSelectors.isOperatorRoleActiveSelector);
    this.isSupportAgentActive = this.store.selectSignal(AuthSelectors.isSupportAgentRoleActiveSelector);
    this.isB2bAccountManagerActive = this.store.selectSignal(AuthSelectors.isB2BRoleActiveSelector);
    this.isFinanceManagerActive = this.store.selectSignal(AuthSelectors.isFinanceManagerRoleActiveSelector);
    this.isLevel1 = this.store.selectSignal(AuthSelectors.isAccessLevel1Selector);
    this.isLevel2 = this.store.selectSignal(AuthSelectors.isAccessLevel2Selector);

    this.store
      .select(AuthSelectors.isSnapPointsEnabledForUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isSnapPointsEnabled => {
        this.operatorLevel1Menu.update(menu => {
          if (isSnapPointsEnabled) {
            menu.push({ name: 'Pickup locations', route: '/operator/pickup-locations', icon: 'pin_drop' });
          } else {
            menu = menu.filter(item => item.route !== '/operator/pickup-locations');
          }
          return [...menu];
        });
      });

    this.router.events
      .pipe(
        whenPageVisible(),
        filter(
          event =>
            (event.type === EventType.Scroll && event.routerEvent instanceof NavigationEnd) ||
            event instanceof NavigationEnd,
        ),
        map(() => this.store.dispatch(AuthActions.activeUrlChanged({ url: this.router.url }))),
      )
      .subscribe();
  }
}
