import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawerMode, MatSidenavContainer, MatSidenavModule } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppRoutingModule } from '../app-routing.module';
import { AppState } from '../app.state';
import { AuthActions, AuthSelectors } from '../auth';
import { UserAreaSelectComponent } from '../auth/user-area-select/user-area-select.component';
import { UserComponent } from '../auth/user/user.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { EntityLogsComponent } from '../entity-logs/entity-logs.component';
import { NavComponent } from '../nav/nav.component';
import { WindowSizeService } from '../shared/services/window-size.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  imports: [
    CommonModule,
    NavComponent,
    BreadcrumbsComponent,
    UserAreaSelectComponent,
    UserComponent,
    AppRoutingModule,
    MatDividerModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    EntityLogsComponent,
  ],
})
export class MainComponent implements OnInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  isAuthorized$: Observable<boolean>;
  isSideNavOpened$: Observable<boolean>;
  sideNavMode$: Observable<MatDrawerMode>;
  appName = 'Viggo Console';
  isEntityLogsVisible$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private windowSizeService: WindowSizeService,
  ) {}

  ngOnInit(): void {
    this.isAuthorized$ = this.store.select(AuthSelectors.hasAnyRoleSelector);
    this.isSideNavOpened$ = this.store.select(AuthSelectors.isSideNavOpenedSelector);
    this.sideNavMode$ = this.store.select(AuthSelectors.sideNavModeSelector);
    this.isEntityLogsVisible$ = this.store.select(AuthSelectors.isEntityLogsVisibleSelector);
    this.windowSizeService.watchScreenSize();
  }

  toggleSidenav() {
    this.store.dispatch(AuthActions.sideNavToggled());
  }
}
